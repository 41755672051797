import GuestMiddleware from "@/middlewares/GuestMiddleware";
import { RouteRecordRaw } from "vue-router";

export const guest: Array<RouteRecordRaw> = [
  {
    path: "/home",
    component: () => import(/* webpackChunkName: "guest" */ "@/GuestApp.vue"),
    meta: {
      middleware: [GuestMiddleware],
    },
    children: [
      {
        path: "",
        name: "GuestHome",
        component: () => import(/* webpackChunkName: "guest-home" */ "@/views/Guest/GuestHome.vue"),
      },
      {
        path: "theory",
        name: "GuestTheory",
        component: () => import(/* webpackChunkName: "guest-theory" */ "@/views/Guest/GuestTheory.vue"),
      },
      {
        path: "/login",
        name: "Login",
        component: () => import(/* webpackChunkName: "login" */ "@/views/Auth/Login.vue"),
      },
      {
        path: "/forgot-password",
        name: "ForgotPassword",
        component: () => import(/* webpackChunkName: "forgot-password" */ "@/views/ForgotPassword/ForgotPassword.vue"),
      },
      {
        path: "/reset-password",
        name: "ResetPassword",
        component: () => import(/* webpackChunkName: "reset-password" */ "@/views/ResetPassword/ResetPassword.vue"),
      },
      {
        path: "/third-party-payment",
        name: "ThirdPartyPayment",
        component: () => import(/* webpackChunkName: "third-party-payment" */ "@/views/ThirdPartyPayment/ThirdPartyPayment.vue"),
      },
      {
        path: "/qr-code-page",
        name: "QrCodePage",
        component: () => import(/* webpackChunkName: "qr-code-page" */ "@/views/Onboarding/QrCodePage.vue"),
        meta: {
          middleware: [],
        },
      },
      {
        path: "/qr-code-driving-school",
        name: "QrCodeFindDrivingSchool",
        component: () => import(/* webpackChunkName: "qr-code-driving-school" */ "@/views/Onboarding/QrCodeFindDrivingSchool.vue"),
        meta: {
          middleware: [],
        },
      },
      {
        path: "/students/confirm-email",
        component: () => import(/* webpackChunkName: "confirm-email" */ "@/views/Onboarding/Register/ConfirmEmail.vue"),
        name: "ConfirmEmail",
      },
      {
        path: "/register-start",
        component: () => import(/* webpackChunkName: "register-start" */ "@/views/Onboarding/Register/RegistrationStart.vue"),
        name: "RegisterStart",
      },
      {
        path: "resend-done",
        component: () => import(/* webpackChunkName: "resend-done" */ "@/views/Onboarding/Register/RegisterDone.vue"),
        name: "ResendDone",
      },
    ],
  },
  {
    path: "/fastlanes/:drivingSchool",
    component: () => import(/* webpackChunkName: "fastlanes" */ "@/views/Onboarding/Onboarding.vue"),
    meta: {
      middleware: [GuestMiddleware],
    },
    children: [
      {
        path: "",
        component: () => import(/* webpackChunkName: "register-course-overview" */ "@/views/Onboarding/Register/RegisterCourseOverview.vue"),
        name: "RegisterCourseOverview",
      },
      {
        path: "course-overview-form",
        component: () => import(/* webpackChunkName: "register-course-overview-form" */ "@/views/Onboarding/Register/RegisterCourseOverviewForm.vue"),
        name: "RegisterCourseOverviewForm",
      },
      {
        path: "register-done",
        component: () => import(/* webpackChunkName: "fastlanes-register-done" */ "@/views/Onboarding/Register/RegisterDone.vue"),
        name: "FastlanesRegisterDone",
      },
    ],
  },
  {
    path: "/onboarding/:drivingSchool",
    name: "Register",
    redirect: (to) => ({
      path: `/fastlanes/${to.params.drivingSchool}`,
    }),
  },
  {
    path: "/onboarding/:drivingSchool/register-form",
    name: "RegisterForm",
    redirect: (to) => ({
      path: `/fastlanes/${to.params.drivingSchool}`,
    }),
  },
];

export default guest;
