import { State } from "./state";
import { MutationTree } from "vuex";
import { IS_LOADING, SET_ERROR, SET_SUCCESS, SET_DATA_LIST, SET_TOTAL } from "./types";

export default <MutationTree<State<any>>>{
  [IS_LOADING](state: State<any>, isLoading: boolean) {
    Object.assign(state, { isLoading });
  },

  [SET_ERROR](state: State<any>, error: Record<string, any> | null) {
    Object.assign(state, { error });
  },

  [SET_SUCCESS](state: State<any>, success: boolean) {
    Object.assign(state, { success });
  },

  [SET_DATA_LIST](state: State<any>, dataList: Record<string, any>) {
    Object.assign(state, { dataList });
  },

  [SET_TOTAL](state: State<any>, total: number) {
    Object.assign(state, { total });
  },
};
