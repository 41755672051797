import { ActionTree } from "vuex";
import { State } from "./state";
import axios from "@/utils/axios";

export const loadBalance = async (store: any, studentEducation: any): Promise<any> => {
  store.commit("SET_BALANCE_LOADING", true);
  store.commit("SET_BALANCE_SUCCESS", null);
  const request: Promise<any> = axios
    .get(`/balance/student-education/${studentEducation.id}`)
    .then((response: any) => {
      store.commit("SET_BALANCE_ITEM", response.data.saldo);
      store.commit("SET_BALANCE_LOADING", false);
      store.commit("SET_BALANCE_SUCCESS", true);
    })
    .catch((error) => {
      store.commit("SET_ERROR", error);
      store.commit("SET_BALANCE_LOADING", false);
      store.commit("SET_BALANCE_SUCCESS", false);
    });

  return request;
};

export const setError = async (store: any, error: any): Promise<void> => {
  store.commit("SET_ERROR", error);
};

export const setBalanceSuccess = async (store: any, success: any): Promise<void> => {
  store.commit("SET_BALANCE_SUCCESS", success);
};

export const setBalanceLoading = async (store: any, loading: any): Promise<void> => {
  store.commit("SET_BALANCE_LOADING", loading);
};

export const setBalanceItem = async (store: any, balanceItem: any): Promise<void> => {
  store.commit("SET_BALANCE_ITEM", balanceItem);
};

export const setSelectedEducation = async (store: any, selectedEducation: any): Promise<void> => {
  store.commit("SET_SELECTED_EDUCATION", selectedEducation);
};

export const reset = async (store: any): Promise<void> => {
  store.commit("SET_SELECTED_EDUCATION", null);
  store.commit("SET_ERROR", null);
  store.commit("SET_BALANCE_ITEM", null);
  store.commit("SET_BALANCE_LOADING", false);
  store.commit("SET_BALANCE_SUCCESS", null);
};

export default <ActionTree<State, any>>{
  setError,
  setBalanceSuccess,
  setBalanceLoading,
  setBalanceItem,
  setSelectedEducation,
  loadBalance,
  reset,
};
