import hasToken from "@/helper/hasToken";
import contractHasNotApproved from "@/helper/contractHasNotApproved";
import UserService from "@/services/UserService";
const STUDENT_DEMO_ROLE = "STUDENT_DEMO";

export default (to: any, from: any, next: any) => {
  if (hasToken() && UserService.getRole() === STUDENT_DEMO_ROLE) {
    next({
      name: "BasicDashboard",
    });
  } else if (hasToken()) {
    // disable all routes if contract is not approved
    if (to.name !== "Home" && contractHasNotApproved()) {
      next({ name: "Home" });
    } else {
      next();
    }
  } else {
    next({
      name: "Login",
      //query: { redirect: to.fullPath },
    });
  }
};
