import hasToken from "@/helper/hasToken";
import UserService from "@/services/UserService";
const STUDENT_DEMO_ROLE = "STUDENT_DEMO";

export default (to: any, from: any, next: any) => {
  if (hasToken() && UserService.getRole() === STUDENT_DEMO_ROLE) {
    next();
  } else if (!hasToken()) {
    next({
      name: "Login",
      //query: { redirect: to.fullPath },
    });
  } else {
    next({
      name: "Home",
    });
  }
};
