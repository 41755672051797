import { RouteRecordRaw } from "vue-router";
import BasicAuthMiddleware from "@/middlewares/BasicAuthMiddleware";

export const basic_access: Array<RouteRecordRaw> = [
  {
    path: "/basic",
    component: () => import(/* webpackChunkName: "basic-app" */ "@/BasicApp.vue"),
    meta: {
      middleware: [BasicAuthMiddleware],
    },
    children: [
      {
        path: "",
        component: () => import(/* webpackChunkName: "basic-dashboard" */ "@/views/BasicUser/Dashboard.vue"),
        name: "BasicDashboard",
      },
      {
        path: "full-register/:drivingSchool",
        component: () => import(/* webpackChunkName: "full-register" */ "@/views/BasicUser/FullRegister.vue"),
        children: [
          {
            path: "register-success",
            component: () => import(/* webpackChunkName: "register-success" */ "@/views/Onboarding/RegisterSuccess.vue"),
            name: "RegisterSuccess",
          },
          {
            path: "",
            component: () => import(/* webpackChunkName: "apply-contract" */ "@/views/Onboarding/ApplyContract.vue"),
            name: "ApplyContract",
          },
          {
            path: "fully-complete-registration",
            component: () => import(/* webpackChunkName: "fully-complete-registration" */ "@/views/Onboarding/FullyCompleteRegistration.vue"),
            name: "FullyCompleteRegistration",
          },
          {
            path: "well-done",
            component: () => import(/* webpackChunkName: "well-done" */ "@/views/Onboarding/WellDone.vue"),
            name: "WellDone",
          },
        ],
      },
      {
        path: "/theory-questions",
        name: "BasicTheoryQuestions",
        component: () => import(/* webpackChunkName: "basic_theory_questions" */ "@/views/BasicUser/BasicTheoryQuestions.vue"),
      },
      {
        path: "/more-information",
        component: () => import(/* webpackChunkName: "more-information" */ "@/views/Onboarding/MoreInformation.vue"),
        name: "MoreInformation",
      },
      {
        path: "more-information-done",
        name: "MoreInformationDone",
        component: () => import(/* webpackChunkName: "more-information-done" */ "@/views/Onboarding/MoreInformationDone.vue"),
      },
    ],
  },
];
