import { State } from "./state";
import { MutationTree } from "vuex";

export function SET_ERROR(state: State, sendThirdPartyPaymentEmailError: any) {
  state.sendThirdPartyPaymentEmailError = sendThirdPartyPaymentEmailError;
}

export function SET_SEND_THIRD_PARTY_PAYMENT_SUCCESS(state: State, sendThirdPartyPaymentEmailSuccess: any) {
  state.sendThirdPartyPaymentEmailSuccess = sendThirdPartyPaymentEmailSuccess;
}

export function SET_SEND_THIRD_PARTY_PAYMENT_LOADING(state: State, isSendThirdPartyPaymentEmailLoading: any) {
  state.isSendThirdPartyPaymentEmailLoading = isSendThirdPartyPaymentEmailLoading;
}

export function SET_SELECTED_EDUCATION(state: State, selectedEducation: any) {
  state.selectedEducation = selectedEducation;
}

export default <MutationTree<State>>{
  SET_ERROR,
  SET_SEND_THIRD_PARTY_PAYMENT_SUCCESS,
  SET_SEND_THIRD_PARTY_PAYMENT_LOADING,
  SET_SELECTED_EDUCATION,
};
