import { createStore } from "vuex";
import Auth from "@/store/Auth";
import StudentEducation from "@/store/StudentEducation";
import Student from "@/store/Student";
import Appointments from "@/store/Appointments";
import GlobalMessage from "@/store/GlobalMessage";
import TheoryQuestion from "@/store/TheoryQuestions";
import StudentQuestionAnswer from "@/store/StudentQuestionAnswer";
import Balance from "@/store/Balance";
import TheoryPreExams from "@/store/TheoryPreExams";
import TheoryPreExamProgress from "@/store/TheoryPreExamProgress";
import EducationEvents from "@/store/EducationEvents";
import TheoryPreExamList from "@/store/TheoryPreExams/TheoryPreExamList";
import MostDifficult from "@/store/TheoryQuestions/MostDifficult";
import MoreThenTwoWrong from "@/store/TheoryQuestions/MoreThenTwoWrong";
import QuestionSearch from "@/store/TheoryQuestions/QuestionSearch";
import TheoryQuestionGroup from "@/store/TheoryQuestionGroup";
import LivePayMandate from "@/store/LivePayMandate";
import Country from "@/store/Country";
import StudentDocument from "@/store/StudentDocument";
import TheoryQuestionsVersion from "@/store/TheoryQuestionsVersion";
import StudentPassword from "@/store/StudentPassword";
import LivePay from "@/store/LivePay";
import LoadAccount from "@/store/LoadAccount";
import CollectMoney from "@/store/CollectMoney";
import ThirdPartyPayment from "@/store/ThirdPartyPayment";
import PublicInfo from "@/store/PublicInfo";
import SubmitRegistration from "@/store/SubmitRegistration";
import Broadcast from "@/store/Broadcast";
import { vuexLocal } from "@/store/Persistence/persistance";
import Price from "@/store/Price";
import EmailConfirmation from "@/store/EmailConfirmation";
import TheoryLesson from "@/store/TheoryLesson";
import Profile from "@/store/Profile";
import DrivingSchool from "@/store/DrivingSchool";
import LivePayGoCardless from "@/store/LivePayGoCardless";
import Gocardless from "@/store/Gocardless";
import Abibaro from "@/store/Abibaro";

const store = createStore({
  modules: {
    auth: new Auth(),
    "student-education": new StudentEducation(),
    student: new Student(),
    appointments: new Appointments(),
    "global-message": new GlobalMessage(),
    "theory-questions": new TheoryQuestion(),
    "theory-questions-most-difficult": new MostDifficult(),
    "theory-questions-more-then-two-wrong": new MoreThenTwoWrong(),
    "theory-questions-search": new QuestionSearch(),
    "student-question-answers": new StudentQuestionAnswer(),
    balance: new Balance(),
    "theory-pre-exams": new TheoryPreExams(),
    "theory-pre-exam-list": new TheoryPreExamList(),
    "theory-pre-exam-progress": new TheoryPreExamProgress(),
    "education-events": new EducationEvents(),
    "theory-question-groups": new TheoryQuestionGroup(),
    "theory-question-version": new TheoryQuestionsVersion(),
    "live-pay-mandate": new LivePayMandate(),
    "live-pay": new LivePay(),
    country: new Country(),
    "student-document": new StudentDocument(),
    "student-password": new StudentPassword(),
    "load-account": new LoadAccount(),
    "collect-money": new CollectMoney(),
    "third-party-payment": new ThirdPartyPayment(),
    "public-info": new PublicInfo(),
    "submit-registration": new SubmitRegistration(),
    price: new Price(),
    broadcast: new Broadcast(),
    "email-confirmation": new EmailConfirmation(),
    "theory-lesson": new TheoryLesson(),
    profile: new Profile(),
    "driving-school": new DrivingSchool(),
    "live-pay-go-cardless": new LivePayGoCardless(),
    gocardless: new Gocardless(),
    abibaro: new Abibaro(),
  },
  mutations: {
    setLicenseClass(state, payload) {
      state.licenseClass = payload;
    },
    setTheoryCourse(state, payload) {
      state.theoryCourse = payload;
    },
    setBranch(state, payload) {
      state.branch = payload;
    },
  },
  state: {
    licenseClass: null,
    theoryCourse: null,
    branch: null,
  },
  plugins: [vuexLocal.plugin],
});

export default store;
