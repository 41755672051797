import { State } from "./state";
import { GetterTree } from "vuex";

import { getDataItem, getDataList, getError, getId, getIsLoading, getSuccess, getTotal } from "@/store/Crud/functions/getters";
import { IBroadcast } from "@/interfaces/IBroadcast";

const getUnseenCount = (state: any) => {
  if (!state.list.dataList) return 0;
  return state.list.dataList.filter((b: IBroadcast) => !b.seen).length;
};

export default <GetterTree<State, any>>{
  getError,
  getIsLoading,
  getDataItem,
  getSuccess,
  getDataList,
  getTotal,
  getId,
  getUnseenCount,
};
