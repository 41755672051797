
import { Options, Prop, Vue } from "vue-property-decorator";
import { isPlatform, IonModal, IonHeader, IonToolbar, IonTitle, IonButtons, IonButton, IonContent } from "@ionic/vue";

@Options({
  components: {
    IonModal,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonButtons,
    IonButton,
    IonContent,
  },
  emits: ["hide"],
})
export default class FscModal extends Vue {
  public name = "FscModal";

  public showModal = false;

  public key = 0;

  @Prop({ default: () => "md" })
  public size!: string;

  @Prop()
  public id!: string;

  @Prop({ default: () => true })
  public hideFooter!: boolean;

  @Prop({ default: () => true })
  public hideHeader!: boolean;

  @Prop()
  public modalClass!: string;

  @Prop()
  public dialogClass!: string;

  @Prop()
  public contentClass!: string;

  @Prop({ default: () => true })
  public centered!: boolean;

  @Prop({ default: () => false })
  public noCloseOnBackdrop!: boolean;

  @Prop({ default: () => "" })
  public title!: string;

  @Prop({ default: () => false })
  public hideCloseBtn!: boolean;

  @Prop({ default: () => [0.5, 0.7, 1] })
  public breakpoints!: Array<number>;

  @Prop({ default: () => 0.7 })
  public initialBreakpoint!: number;

  @Prop({ default: () => "" })
  public ionContentClass!: string;

  @Prop({ default: () => "" })
  public customClass!: string;

  public show(): void {
    this.showModal = true;
  }

  public hide(): void {
    this.showModal = false;
  }

  public get isDesktop(): boolean {
    return isPlatform("desktop");
  }

  public willDismiss(): void {
    this.$emit("hide");
  }
}
