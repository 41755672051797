import { CrudState } from "@/store/Crud/State";

export class State extends CrudState {
  theoryCourses: Array<any> = [];
  licenceClasses: Array<any> = [];
  drivingSchool: any = null;
  branches: Array<any> = [];

  languages: Array<any> = [];
  languagesLoading = false;
  languagesError: any = null;

  countries: Array<any> = [];
  countriesLoading = false;
  countriesError: any = null;
  previousLicenceClasses: Array<any> = [];
  previousLicenceClassesLoading = false;
  previousLicenceClassesError: any = null;

  selectedLicenseClass = null;
  selectedTheoryCourse = null;
  theoryCoursesLoading = false;
  licenceClassesLoading = false;
}
