import { State } from "./state";
import { GetterTree } from "vuex";

import { getDataItem, getDataList, getError, getId, getIsLoading, getSuccess, getTotal } from "@/store/Crud/functions/getters";

export const getNextAppointment = (state: State) => state.nextAppointment;

export default <GetterTree<State, any>>{
  getError,
  getIsLoading,
  getDataItem,
  getSuccess,
  getDataList,
  getTotal,
  getId,
  getNextAppointment,
};
