import { AxiosError } from "axios";
import { ActionContext, ActionTree } from "vuex";
import { State } from "./state";
import StorageService from "@/services/StorageService";
import router from "@/router";
import UserService from "@/services/UserService";
import jwtDecode from "jwt-decode";
import { $axios } from "@/utils/axios";

/**
 * @param store
 * @param credentials
 * @returns {Promise<void>}
 */
export const signIn = async (store: any, credentials: Record<string, any>): Promise<void> => {
  store.commit("IS_LOADING", true);
  return await $axios
    .post("/generate-token", credentials)
    .then(({ data }) => {
      if (!data.student) {
        throw new Error("Sie haben keinen Zugriff auf dieser Seite!");
      }
      const decodeToken = jwtDecode(data.token);
      UserService.setTokenInfo(decodeToken);
      data.student.drivingSchoolName = data.drivingSchoolName;
      UserService.setUser(data.student);
      UserService.setRefreshToken(data.refreshToken);
      StorageService.setToStorage("token", data.token);
      store.commit("IS_LOADING", false);
      store.commit("SET_DATA_ITEM", data);
      router.push({ name: "Home" });
    })
    .catch((error: AxiosError) => {
      store.commit("IS_LOADING", false);
      store.commit("SET_ERROR", "Wrong credentials");
    });
};

/**
 * @deprecated
 * @param store
 * @returns {Promise<void>}
 */
export const signOut = async (store: any) => {
  StorageService.removeFromStorage("token");
  StorageService.removeFromStorage("tokenInfo");
  StorageService.removeFromStorage("user");
  sessionStorage.removeItem("vuex");
  setTimeout(() => {
    location.reload();
  }, 500);
};

export const setLoading = async (store: any, loading: boolean): Promise<void> => {
  store.commit("IS_LOADING", loading);
};

export const refreshToke = async (store: ActionContext<any, any>) => {
  //
};

export default <ActionTree<State, any>>{
  signIn,
  signOut,
  setLoading,
  refreshToke,
};
