import { ActionTree } from "vuex";
import { State } from "./state";
import { create, filter, findOne, findAll, update } from "@/store/Crud/functions/actions";

const URL = "/student-educations";

export const selectTheoryStudentEducation = async (store: any, studentEducation: any | null): Promise<void> => {
  store.commit("SET_SELECTED_THEORY_STUDENT_EDUCATION", studentEducation);
};

export const selectPreExamStudentEducation = async (store: any, studentEducation: any | null): Promise<void> => {
  store.commit("SET_SELECTED_PREEXAM_STUDENT_EDUCATION", studentEducation);
  store.dispatch("theory-pre-exams/resetExam", {}, { root: true });
};

export const selectPreExamStudentEducationWithoutReset = async (store: any, studentEducation: any) => {
  store.commit("SET_SELECTED_PREEXAM_STUDENT_EDUCATION", studentEducation);
};

export const selectAccountStudentEducation = async (store: any, studentEducation: any | null): Promise<void> => {
  store.commit("SET_SELECTED_ACCOUNT_STUDENT_EDUCATION", studentEducation);
};

export const selectPlaygroundStudentEducation = async (store: any, studentEducation: any | null): Promise<void> => {
  store.commit("SET_SELECTED_PLAYGROUND_STUDENT_EDUCATION", studentEducation);
};

export const setLearningMode = async (store: any, learningMode: string): Promise<void> => {
  store.commit("SET_LEARNING_MODE", learningMode);
};

export default <ActionTree<State, any>>{
  create,
  filter,
  findOne,
  findAll,
  update,
  selectTheoryStudentEducation,
  selectPreExamStudentEducation,
  selectAccountStudentEducation,
  selectPlaygroundStudentEducation,
  setLearningMode,
  selectPreExamStudentEducationWithoutReset,
};
