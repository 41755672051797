import { State } from "./state";
import { GetterTree } from "vuex";

import { getDataItem, getDataList, getError, getId, getIsLoading, getSuccess } from "@/store/Crud/functions/getters";
import { CrudState } from "@/store/Crud/State";

export const getUnseenCount = (state: CrudState) =>
  state.dataList.filter((doc: any) => !doc.seenByStudent && Boolean(!doc?.costBearerDocument)).length;

export default <GetterTree<State, any>>{
  getError,
  getIsLoading,
  getDataItem,
  getSuccess,
  getDataList,
  getUnseenCount,
  getId,
};
