import { ActionContext, ActionTree } from "vuex";
import { State } from "./state";
import { ICrudOptions } from "@/interfaces/ICrudOptions";
import { IS_LOADING, SET_DATA, SET_ERROR, SET_SUCCESS } from "./types";
import { AxiosError, AxiosResponse } from "axios";
import axios from "@/utils/axios";
import MessageDispatcher from "@/store/Crud/classes/MessageDispatcher";
import i18n from "@/i18n";

const messageDispatcher = new MessageDispatcher();

const update = async (store: ActionContext<State<any>, any>, options: ICrudOptions) => {
  store.commit(IS_LOADING, true);
  store.commit(SET_SUCCESS, false);
  store.commit(SET_ERROR, null);

  return await axios
    .put(options.resource, options.data)
    .then((response: AxiosResponse) => {
      store.commit(SET_DATA, response.data);
      store.commit(SET_SUCCESS, true);
      if (!options.hideSuccessMessage) {
        //@ts-ignore
        messageDispatcher.dispatchSuccessMessage(store, i18n.global.t("messages.save_success", "").toString());
      }
    })
    .catch((error: AxiosError) => {
      store.commit(SET_ERROR, error.response?.data);
    })
    .finally(() => {
      store.commit(IS_LOADING, false);
    });
};

export default <ActionTree<State<any>, any>>{
  update,
};
