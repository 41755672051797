import { State } from "./state";
import { GetterTree } from "vuex";

export const getError = (state: State) => state.error;
export const getSuccess = (state: State) => state.success;
export const getIsLoading = (state: State) => state.isLoading;
export const getDataItem = (state: State) => state.dataItem;
export const getDataList = (state: State) => state.dataList;

export default <GetterTree<State, any>>{
  getError,
  getIsLoading,
  getDataItem,
  getSuccess,
  getDataList,
};
