import { ActionContext } from "vuex";
import { CrudState } from "@/store/Crud/State";
import { ICrudOptions } from "@/interfaces/ICrudOptions";
import CrudActions from "@/store/Crud/classes/CrudActions";
import CrudResetActions from "@/store/Crud/classes/CrudResetActions";

export const crudActions: CrudActions = new CrudActions();
export const crudResetActions: CrudResetActions = new CrudResetActions();

export function getOne(store: ActionContext<CrudState, any>, options: ICrudOptions): Promise<any> {
  return crudActions.getOne(store, options);
}
export function findOne(store: ActionContext<CrudState, any>, options: ICrudOptions): Promise<any> {
  return crudActions.findOne(store, options);
}

export async function findAll(store: ActionContext<CrudState, any>, options: ICrudOptions): Promise<any> {
  await crudActions.findAll(store, options);
}

export async function filter(store: ActionContext<CrudState, any>, options: ICrudOptions): Promise<any> {
  await crudActions.filter(store, options);
}

export async function create(store: ActionContext<CrudState, any>, options: ICrudOptions): Promise<any> {
  await crudActions.create(store, options);
}

export async function simplePost(store: ActionContext<CrudState, any>, options: ICrudOptions): Promise<any> {
  await crudActions.simplePost(store, options);
}

export async function update(store: ActionContext<CrudState, any>, options: ICrudOptions): Promise<any> {
  await crudActions.update(store, options);
}

export async function del(store: ActionContext<CrudState, any>, options: ICrudOptions): Promise<any> {
  await crudActions.delete(store, options);
}

export function resetList(store: ActionContext<CrudState, any>, options: ICrudOptions): void {
  crudResetActions.resetList(store, options);
}

export function resetSave(store: ActionContext<CrudState, any>, options: ICrudOptions): void {
  crudResetActions.resetSave(store, options);
}

export function dispatchGlobalError(store: ActionContext<CrudState, any>): void {
  store.dispatch(
    "global-message/setMessage",
    {
      text: "",
      type: "error",
    },
    { root: true }
  );
}
