import { createApp } from "vue";
import { IonicVue } from "@ionic/vue";
import BootstrapVue3 from "bootstrap-vue-3";
import FloatingVue from "floating-vue";
// @ts-ignore
import VueSignaturePad from "vue-signature-pad";
import "floating-vue/dist/style.css";

import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./i18n";
import Vue3FiltersPlugin from "@/plugins/Vue3FiltersPlugin";
import Vue3MomentPlugin from "@/plugins/Vue3MomentPlugin";
import Vue3ToastedPlugin from "@/plugins/Vue3ToastedPlugin";

import "./assets/sass/app.scss";
import ClickableOnMobile from "@/directives/ClickableOnMobile";
import FscModal from "@/components/FscModal.vue";
import MoneyMixin from "@/mixins/MoneyMixin";

const app = createApp(App)
  .use(IonicVue)
  .use(router)
  .use(i18n)
  .use(store)
  .use(Vue3MomentPlugin)
  .use(Vue3ToastedPlugin, {
    duration: 5000,
    theme: "outline",
    iconPack: "callback",
  })
  .use(Vue3FiltersPlugin)
  .use(BootstrapVue3)
  .use(FloatingVue)
  .use(VueSignaturePad);

app.directive("clickable-mobile", ClickableOnMobile);
app.component("fsc-modal", FscModal);
app.mixin(MoneyMixin);

router.isReady().then(() => {
  app.mount("#app");
});
