import { App, Plugin } from "vue";

//@ts-ignore
import util from "vue2-filters/src/util";
//@ts-ignore
import * as stringFilters from "vue2-filters/src/string";
//@ts-ignore
import * as arrayFilters from "vue2-filters/src/array";
//@ts-ignore
import * as otherFilters from "vue2-filters/src/other";

const Vue3FiltersPlugin: Plugin = {
  install: function (app: App) {
    util.each(stringFilters, function (value: any, key: any) {
      app.config.globalProperties.$filters = {
        ...app.config.globalProperties.$filters,
        [key]: value,
      };
    });

    util.each(otherFilters, function (value: any, key: any) {
      app.config.globalProperties.$filters = {
        ...app.config.globalProperties.$filters,
        [key]: value,
      };
    });

    app.mixin({
      methods: { ...arrayFilters },
    });
  },
};

export default Vue3FiltersPlugin;
