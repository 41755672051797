import { State } from "./state";
import { GetterTree } from "vuex";

import { getDataItem, getDataList, getError, getId, getIsLoading, getSuccess, getTotal } from "@/store/Crud/functions/getters";

export const getMandateId = (state: State) => state.mandateId;
export const getAmountInEur = (state: State) => state.amountInEur;
export default <GetterTree<State, any>>{
  getError,
  getIsLoading,
  getDataItem,
  getSuccess,
  getDataList,
  getTotal,
  getId,
  getMandateId,
  getAmountInEur,
};
