import axios from "@/utils/axios";
import { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { ref } from "vue";

export default <T>() => {
  const data = ref<T | null>(null);
  const loading = ref(false);
  const error = ref(null);
  const success = ref(false);

  const fetch = async (config: AxiosRequestConfig) => {
    loading.value = true;
    await axios
      .request<T>(config)
      .then((response: AxiosResponse) => {
        data.value = response.data;
        success.value = true;
      })
      .catch((err: AxiosError) => {
        error.value = err.response?.data;
      })
      .finally(() => {
        loading.value = false;
      });
  };

  return {
    fetch,
    data,
    loading,
    error,
    success,
  };
};
