import { State } from "./state";
import { GetterTree } from "vuex";

export const getBalanceError = (state: State) => state.balanceError;
export const getBalanceSuccess = (state: State) => state.balanceSuccess;
export const getIsBalanceLoading = (state: State) => state.isBalanceLoading;
export const getBalanceItem = (state: State) => state.balanceItem;
export const getSelectedEducation = (state: State) => state.selectedEducation;

export default <GetterTree<State, any>>{
  getBalanceError,
  getBalanceSuccess,
  getIsBalanceLoading,
  getBalanceItem,
  getSelectedEducation,
};
