import { ActionTree, ActionContext } from "vuex";
import { State } from "./state";

export function setMessage(store: ActionContext<State, any>, data: any) {
  store.commit("MESSAGE", data);
}
export function resetMessages(store: ActionContext<State, any>) {
  store.commit("MESSAGE", null);
}
export default <ActionTree<State, any>>{
  setMessage,
  resetMessages,
};
