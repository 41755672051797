import { ActionContext } from "vuex";

/**
 * Dispatches messages to the global-message store
 */
export default class MessageDispatcher {
  /**
   * dispatch root action to show global-message message
   * @param store
   * @param error
   */
  protected dispatchMessage(store: ActionContext<any, any>, message: string, type: string): Promise<any> {
    return store.dispatch(
      "global-message/setMessage",
      {
        text: message,
        type: type,
      },
      { root: true }
    );
  }

  /**
   *
   * @param store
   * @param message
   */
  public dispatchSuccessMessage(store: ActionContext<any, any>, message: string): Promise<any> {
    return this.dispatchMessage(store, message, "success");
  }

  /**
   *
   * @param store
   * @param message
   */
  public dispatchErrorMessage(store: ActionContext<any, any>, message: string): Promise<any> {
    return this.dispatchMessage(store, message, "error");
  }

  /**
   *
   * @param store
   * @param message
   */
  protected dispatchInfoMessage(store: ActionContext<any, any>, message: string): Promise<any> {
    return this.dispatchMessage(store, message, "info");
  }
}
