import { ActionTree } from "vuex";
import { State } from "./state";
import { create, filter, findAll, findOne, update, getOne, resetSave, dispatchGlobalError } from "@/store/Crud/functions/actions";
import { IS_LOADING, SET_ERROR } from "@/store/TheoryQuestions/types";
import axios from "@/utils/axios";

export const markFavourite = async (store: any, params: any): Promise<void> => {
  store.commit(IS_LOADING, true);
  store.commit("SET_MARKED_FAVOURITE_SUCCESS", null);
  return await axios
    .post(
      `theory-questions/favourites?studentEducationId=${params.studentEducationId}&theoryQuestionId=${params.theoryQuestionId}&favourite=${params.favourite}`
    )
    .then(({ data: any }) => {
      store.commit(IS_LOADING, false);
      store.commit("SET_MARKED_FAVOURITE_SUCCESS", true);
    })
    .catch(({ response }) => {
      store.commit(IS_LOADING, false);
      store.commit("SET_MARKED_FAVOURITE_SUCCESS", false);
      store.commit(SET_ERROR, response.data);
      dispatchGlobalError(store);
    });
};

export const setMarkFavouriteSuccess = (store: any, success: boolean) => store.commit("SET_MARKED_FAVOURITE_SUCCESS", success);

export default <ActionTree<State, any>>{
  create,
  filter,
  findOne,
  update,
  findAll,
  getOne,
  markFavourite,
  resetSave,
  setMarkFavouriteSuccess,
};
