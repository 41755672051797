import { State } from "./state";
import { MutationTree } from "vuex";

export function SET_ERROR(state: State, balanceError: any) {
  state.balanceError = balanceError;
}

export function SET_BALANCE_SUCCESS(state: State, balanceSuccess: any) {
  state.balanceSuccess = balanceSuccess;
}

export function SET_BALANCE_LOADING(state: State, isBalanceLoading: any) {
  state.isBalanceLoading = isBalanceLoading;
}

export function SET_BALANCE_ITEM(state: State, balanceItem: any) {
  state.balanceItem = balanceItem;
}

export function SET_SELECTED_EDUCATION(state: State, selectedEducation: any) {
  state.selectedEducation = selectedEducation;
}

export default <MutationTree<State>>{
  SET_ERROR,
  SET_BALANCE_SUCCESS,
  SET_BALANCE_LOADING,
  SET_BALANCE_ITEM,
  SET_SELECTED_EDUCATION,
};
