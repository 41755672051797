
import { defineComponent, onUnmounted, onMounted } from "vue";
import { App as CapacitorApp } from "@capacitor/app";
import { SplashScreen } from "@capacitor/splash-screen";
import { IonModal, alertController, isPlatform } from "@ionic/vue";
import useAppStore from "@/composable/useAppStore";
import useRequest from "@/composable/useRequest";
import { useRouter } from "vue-router";
import { IAppVersion } from "@/interfaces/IAppVersion";
import { Capacitor } from "@capacitor/core";
import semver from "semver";

export default defineComponent({
  name: "App",

  components: {
    IonModal,
  },
  setup() {
    const { onClickGoogleStoreDriveBuzz, onClickAppStoreDriveBuzz } = useAppStore();
    const { fetch, data: appsVersion } = useRequest<Array<IAppVersion>>();
    const router = useRouter();

    const onClickGoToStore = () => {
      if (isPlatform("ios")) {
        onClickAppStoreDriveBuzz();
      } else if (isPlatform("android")) {
        onClickGoogleStoreDriveBuzz();
      }
    };

    const checkAppVersion = async () => {
      const appInfo = await CapacitorApp.getInfo();

      // check version from backend
      await fetch({
        url: "/app-version/latest-versions",
        method: "get",
      });

      await hideSplashScreen();

      const driveBuzz = appsVersion.value?.find((appVersion: IAppVersion) => appVersion.name === "drive-buzz");

      if (driveBuzz) {
        const driveBuzzLatestVersion = driveBuzz.latestVersion || "1.0.0";

        const hasNewVersion = semver.gt(driveBuzzLatestVersion, appInfo.version);

        if (hasNewVersion) {
          void openAlert(driveBuzzLatestVersion, appInfo.version);
        }
      }
    };

    const hideSplashScreen = async () => {
      return await SplashScreen.hide();
    };

    const openAlert = async (newVersion: string, currentVersion: string) => {
      const store = isPlatform("android") ? "Google Play" : "App Store";
      const alert = await alertController.create({
        message: `Bitte aktualisiere deine drive.buzz App in ${store}`,
        buttons: [
          {
            text: "Aktualisieren",
            role: "confirm",
            handler: () => {
              onClickGoToStore();
              return false;
            },
          },
        ],
        backdropDismiss: false,
      });

      return await alert.present();
    };

    onMounted(() => {
      if (Capacitor.isNativePlatform()) {
        checkAppVersion();
      }
      CapacitorApp.addListener("appUrlOpen", (data) => {
        // We accept deep-links from both "https://go.drive.buzz" and "https://staging.drivebuzz.fscockpit.de"
        // so we need to extract URI part from given URL in both cases (staging and prod)
        const deepLinkPath = data.url.replace("https://go.drive.buzz", "").replace("https://staging.drivebuzz.fscockpit.de", "");
        router.push(deepLinkPath);
      });
    });

    onUnmounted(() => {
      CapacitorApp.removeAllListeners();
    });
    return {};
  },
});
