import { State } from "./state";
import { MutationTree } from "vuex";
import { IS_LOADING, SET_DATA_ID, SET_DATA_ITEM, SET_DATA_LIST, SET_ERROR, SET_SUCCESS, SET_TOTAL } from "@/store/Crud/functions/mutations";
import cloneDeep from "lodash/cloneDeep";

export function SET_ANSWER_SUCCESS(state: State, answerSuccess: any) {
  state.answerSuccess = answerSuccess;
}

export function SET_QUESTIONS(state: State, questions: Array<any>) {
  state.questions = cloneDeep(questions);
}
export function SET_BASIC_QUESTIONS(state: State, questions: Array<any>) {
  state.basicQuestions = cloneDeep(questions);
}
export function SET_SPECIFIC_QUESTIONS(state: State, questions: Array<any>) {
  state.specificQuestions = cloneDeep(questions);
}

export default <MutationTree<State>>{
  IS_LOADING,
  SET_ERROR,
  SET_SUCCESS,
  SET_DATA_ITEM,
  SET_DATA_LIST,
  SET_TOTAL,
  SET_DATA_ID,
  SET_ANSWER_SUCCESS,
  SET_QUESTIONS,
  SET_BASIC_QUESTIONS,
  SET_SPECIFIC_QUESTIONS,
};
