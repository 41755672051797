import { ActionTree } from "vuex";
import { State } from "./state";
import { create, filter, findAll, findOne, getOne, resetSave, update } from "@/store/Crud/functions/actions";
import { IS_LOADING, SET_DATA_ITEM, SET_ERROR, SET_MY_DRIVING_SCHOOL_CONFIG } from "@/store/DrivingSchool/types";
import axios from "@/utils/axios";

const URL = "/driving-schools";

export const findCurrentDrivingSchool = async (store: any): Promise<void> => {
  store.commit(IS_LOADING, true);
  return await axios
    .get(`${URL}/current`)
    .then(({ data }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_DATA_ITEM, data);
    })
    .catch(({ response }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_ERROR, response.data);
    });
};

export const findMyDrivingSchoolConfig = async (store: any): Promise<void> => {
  store.commit(IS_LOADING, true);
  return await axios
    .get(`${URL}/current/my-driving-school-config`)
    .then(({ data }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_MY_DRIVING_SCHOOL_CONFIG, data);
    })
    .catch(({ response }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_ERROR, response.data);
    });
};

export default <ActionTree<State, any>>{
  create,
  filter,
  findOne,
  update,
  findAll,
  getOne,
  resetSave,
  findCurrentDrivingSchool,
  findMyDrivingSchoolConfig,
};
