import { ActionTree } from "vuex";
import { State } from "./state";
import { create, filter, findAll, findOne, update } from "@/store/Crud/functions/actions";
import { SET_NEXT_APPOINTMENT } from "./types";

export const setNextAppointment = (store: any, appointment: any): void => {
  store.commit(SET_NEXT_APPOINTMENT, appointment);
};

export default <ActionTree<State, any>>{
  create,
  filter,
  findOne,
  update,
  findAll,
  setNextAppointment,
};
