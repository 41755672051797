import VuexPersistence from "vuex-persist";
import CryptoService from "@/services/CryptoService";

const storage: any = {
  getItem: (key: string): any => {
    const storage = sessionStorage.getItem(key);
    if (storage) {
      const decryptedStore = CryptoService.decode(storage);
      return JSON.parse(decryptedStore);
    }
  },
  setItem: (key: string, value: string): void => {
    const valueToString = JSON.stringify(value);
    const encryptedValue = CryptoService.encode(valueToString);
    sessionStorage.setItem(key, encryptedValue);
  },
  removeItem: (key: string): void => {
    sessionStorage.removeItem(key);
  },
};

export const vuexLocal = new VuexPersistence({
  storage: storage,
  modules: ["student-education"],
});
