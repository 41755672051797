import { Module, ModuleTree } from "vuex";
import { State } from "./state";
import Mutations from "./mutations";
import Getters from "./getters";
import Actions from "./actions";
import Item from "@/store/Abstract/Item";
import Create from "../Abstract/Create";

export default class TheoryQuestion implements Module<State, any> {
  namespaced = true;
  state: State;
  mutations = Mutations;
  getters = Getters;
  actions = Actions;
  modules: ModuleTree<any>;

  constructor() {
    this.state = new State();
    this.modules = {
      guest: new Item(),
      learningMode: new Item(),
      learningModeCreate: new Create(),
    };
  }
}
